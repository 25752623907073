import React from 'react';
import PropTypes from 'prop-types';
import StarRatings from 'react-star-ratings';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import formatDate from '../../utilities/dateFormatter';
import { baseUrl } from '../../utilities/constants';

// ------------------------------ Styled Components------------------------
const StyledRow = styled.div`
  color: #fff;
  margin-left: 20px;
  margin-top: 40px;
`;

const StyledRowHeading = styled.h2`
  font-family: 'Source Sans Pro', Arial, sans-serif;
  margin-bottom: 16px;
  text-align: center;

  @media (min-width: 768px) {
    text-align: start;
  }
`;

const StyledRowPosters = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 768px) {
    flex-wrap: nowrap;
    justify-content: unset;
    overflow-x: scroll;
  }
`;

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 176px;
`;

const StyledReleaseDate = styled.p`
  color: gray;
  margin-top: 8px;
  text-align: center;
`;

const StyledTitle = styled.h4`
  margin-bottom: 8px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: inherit;
`;

const StyledRowPoster = styled.img`
  cursor: pointer;
  margin-right: 10px;
  max-height: 100px;
  min-width: 100%;
  object-fit: contain;
  transition: transform 450ms;

  @media (min-width: 1024px) {
    &:hover {
      transform: scale(1.1);
    }
  }
`;

const StyledRowPosterLarge = styled.img`
  cursor: pointer;
  margin-right: 10px;
  max-height: 250px;
  min-width: 100%;
  object-fit: contain;
  transition: transform 450ms;
  
  @media (min-width: 1024px) {
    &:hover {
      transform: scale(1.11);
    }
  }
`;

const Movies = ({ title, isLargeRow, movies }) => {
  const navigate = useNavigate();

  const movieOnClickHandler = (movieId) => {
    window.scrollTo(0, 0);
    navigate(`/details?movieId=${movieId}`);
  }

  return (
    <StyledRow>
      <StyledRowHeading>{title}</StyledRowHeading>
      <StyledRowPosters>
        {movies.map((mov) => (
              <React.Fragment key={mov.id}>
                <StyledContainer>
                  {isLargeRow ? (
                    <StyledRowPosterLarge
                      src={`${baseUrl}${
                        isLargeRow ? mov.poster_path : mov.backdrop_path
                      }`}
                      alt={mov.title}
                      onClick={() => movieOnClickHandler(mov.id)}
                    />
                  ) : (
                    <StyledRowPoster
                      src={`${baseUrl}${
                        isLargeRow ? mov.poster_path : mov.backdrop_path
                      }`}
                      alt={mov.title}
                      onClick={() => movieOnClickHandler(mov.id)}
                    />
                  )}
                  <StyledTitle>
                    {mov.title ? mov.title : mov.original_name}
                  </StyledTitle>
                  <StarRatings
                    className="stars"
                    rating={mov.vote_average / 2}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="#D68910"
                    numberOfStars={5}
                  />
                  <StyledReleaseDate>
                    {formatDate(mov.release_date) ||
                      formatDate(mov.first_air_date)}
                  </StyledReleaseDate>
                </StyledContainer>
              </React.Fragment>
            ))}
      </StyledRowPosters>
    </StyledRow>
  );
}

Movies.propTypes = {
  title: PropTypes.string.isRequired,
  movies: PropTypes.array.isRequired,
  isLargeRow: PropTypes.bool.isRequired,
};

export default Movies;
