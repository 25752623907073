import React from 'react'
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Copyright = () => 
    <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link
            color="inherit"
            href="https://www.linkedin.com/in/m-umar-naeem-b9020814b/"
            target="_blank"
            rel="noopener noreferrer"
        >
            Developer&apos;s Profile
        </Link>{' '}
        {new Date().getFullYear()}.
    </Typography>

const FooterContent = () => <>
    <Typography variant="h5" align="center" gutterBottom>
      TMDB by Umar
    </Typography>
    <Typography
    variant="subtitle1"
    align="center"
    color="textSecondary"
    component="p"
    >
        All rights belong to their respective owners
    </Typography>
    <Copyright />
</>

export default FooterContent;