import React from "react";
import styled from 'styled-components';
import YouTube from 'react-youtube';

import { baseUrl } from "../../utilities/constants";
import extractYear from '../../utilities/extractYear';
import extractKey from "../../utilities/extractVideoKey";
import formatRuntime from '../../utilities/formatRuntime';
import formatCharacterName from "../../utilities/formatCharacterName";

import Star from './star.png';
import Clock from './clock.png';
import Tagline from './tagline.png';
import NotFoundImg from './not-found.jpeg';

const StyledContainer = styled.div`
  margin: 0 40px;
`;

const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 100px 0 50px 0;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const PosterImage = styled.img`
  border-radius: 5px;
  border: 5px solid white;
  height: 300px;
  width: 200px;
`;

const FurtherDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MovieTitle = styled.h1`
  color: #fff;
  font-family: Arimo,Arial,Helvetica,"Lucida Grande",sans-serif;
  margin: unset;
`;

const SubDetails = styled.h3`
  color: #fff;
  font-family: Arimo,Arial,Helvetica,"Lucida Grande",sans-serif;
  margin: unset;
`;

const StyledStar = styled.img`
  height: 16px;
  width: 16px;
`;

const StyledClock = styled.img`
  height: 18px;
  width: 18px;
`;

const VoteCount = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledOverview = styled.div`
  color: #fff;
  margin-bottom: 20px;

  @media (min-width: 425px) {
    width: 350px;
  }

  @media (min-width: 768px) {
    width: 600px;
  }
`;

const StyledYTPlayer = styled(YouTube)`
  height: 175px;
  width: 300px;

  @media (min-width: 425px) {
    height: 200px;
    width: 350px;
  }

  @media (min-width: 768px) {
    height: 350px;
    width: 600px;
  }
`;

const TrailerAndCast = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media (min-width: 1025px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const CastContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 32px;

  @media (min-width: 1025px) {
    margin-top: 0;
  }
`;

const CastHeading = styled.h2`
  color: #fff;
  font-family: Arimo,Arial,Helvetica,"Lucida Grande",sans-serif;
  margin: unset;
  margin-right: 200px;
`;

const CastRow = styled.div`
  align-items: center;
  border-bottom: 1px solid #2f2f2f;
  display: flex;
  gap: 5px;
`;

const CastImage = styled.img`
  height: 55px;
  width: 55px;
  border-radius: 150px;
  margin-bottom: 10px;
`;

const ActorName = styled.div`
  color: #919191;
  font-size: .95em;
  font-weight: bold;
`;

const CharacterName = styled.div`
  color: #fff;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 768px) {
    max-width: 300px;
  }
`;

const TrailerOverview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MovieDetails = ({ details, videos, cast }) => {

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <StyledContainer>
      <StyledDetails>
        <PosterImage
          src={details.poster_path
            ? `${baseUrl}${details.poster_path}`
            : details.backdrop_path
            ? `${baseUrl}${details.backdrop_path}`
            : NotFoundImg}
          alt={details.original_title}
        />
        <FurtherDetails>
          <MovieTitle>{details.original_title}</MovieTitle>
          <SubDetails>{extractYear(details.release_date)}</SubDetails>
          <SubDetails>
            {details?.genres?.map((genre, index) => (
              <React.Fragment key={genre.id}>
                {genre.name} {index !== (details?.genres?.length - 1) && '/ '}
              </React.Fragment>
            ))}
          </SubDetails>
          <VoteCount>
            <StyledStar src={Star} alt="star" />
            <SubDetails>{details?.vote_average}</SubDetails>
          </VoteCount>
          <VoteCount>
            <StyledClock src={Clock} alt="clock" />
            <SubDetails>{formatRuntime(details?.runtime)}</SubDetails>
          </VoteCount>
          <VoteCount>
            <StyledClock src={Tagline} alt="tagline" />
            <SubDetails>{details?.tagline || 'Unknown'}</SubDetails>
          </VoteCount>
        </FurtherDetails>
      </StyledDetails>
      <TrailerAndCast>
        <TrailerOverview>
          <StyledYTPlayer videoId={extractKey(videos)} opts={opts} />
          <StyledOverview>{details?.overview}</StyledOverview>
        </TrailerOverview>
        {cast.length && 
          <CastContainer>
            <CastHeading>Top Cast</CastHeading>
              {cast.slice(0, 5).map((actor) => (
                <CastRow key={actor.id}>
                  <CastImage src={`${baseUrl}${actor?.profile_path}`} alt={actor.id} />
                  <ActorName>{actor?.original_name} as </ActorName>
                  <CharacterName>{formatCharacterName(actor?.character)}</CharacterName>
                </CastRow>
              ))}
          </CastContainer>
        }
      </TrailerAndCast>
    </StyledContainer>
  )
};

export default MovieDetails;