import React from 'react';
import styled from 'styled-components';

import FooterContent from '../FooterContent';

const StyledFooter = styled.footer`
  background-color: whitesmoke;
`;

const Footer = () => <StyledFooter>
    <FooterContent />
</StyledFooter>

export default Footer;