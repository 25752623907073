import instance from '../../../utilities/axios';

export const FETCH_SERIES_REQUEST = 'FETCH_SERIES_REQUEST';

export const FETCH_TRENDING_SERIES_SUCCESS = 'FETCH_TRENDING_SERIES_SUCCESS';
export const FETCH_TOP_RATED_SERIES_SUCCESS = 'FETCH_TOP_RATED_SERIES_SUCCESS';

export const FETCH_SERIES_FAILURE = 'FETCH_SERIES_FAILURE';

export const fetchSeriesRequest = () => ({
    type: FETCH_SERIES_REQUEST,
});

export const fetchTrendingSeriesSuccess = (series) => ({
    type: FETCH_TRENDING_SERIES_SUCCESS,
    payload: series,
});

export const fetchTopRatedSeriesSuccess = (series) => ({
  type: FETCH_TOP_RATED_SERIES_SUCCESS,
  payload: series,
});

export const fetchSeriesFailure = (error) => ({
    type: FETCH_SERIES_FAILURE,
    payload: error,
});

export const fetchSeries = (fetchUrl, category) => (dispatch) => {
    dispatch(fetchSeriesRequest());
    instance
      .get(fetchUrl)
      .then((response) => {
        // response.data is the movies
        const series = response.data.results;
        dispatch(
          category === "trending"
          ? fetchTrendingSeriesSuccess(series)
          : fetchTopRatedSeriesSuccess(series)
        );
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchSeriesFailure(error.message));
      });
};