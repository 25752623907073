import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import TabSwitcher from '../../components/TabSwitcher';
import Series from '../../components/Series';

import { fetchSeries } from '../../redux';
import requests from '../../utilities/requests';

const TV = () => {
    const trending = "trending";
    const topRated = "topRated";
    const dispatch = useDispatch();
    const seriesSelector = useSelector((state) => state.series);

    useEffect(() => {
        dispatch(fetchSeries(requests.fetchTrendingSeries, trending));
        // eslint-disable-next-line
      }, [requests.fetchTrendingSeries]);
    
    useEffect(() => {
        dispatch(fetchSeries(requests.fetchTopRatedSeries, topRated));
        // eslint-disable-next-line
    }, [requests.fetchTopRatedSeries]);

    return (
    <>
        <Nav />
        <TabSwitcher />
        <Series
            title="Trending Series"
            isLargeRow
            series={seriesSelector?.trendingSeries}
        />
        <Series
            title="Top Rated Series"
            isLargeRow
            series={seriesSelector?.topRatedSeries}
        />
        <Footer />
    </>
    )
};

export default TV;

