import {
    FETCH_SERIES_REQUEST,
    FETCH_TRENDING_SERIES_SUCCESS,
    FETCH_TOP_RATED_SERIES_SUCCESS,
    FETCH_SERIES_FAILURE
} from './action';

const seriesInitialState = {
    loading: false,
    trendingSeries: [],
    topRatedSeries: [],
    error: '',
}

const seriesReducer = (state = seriesInitialState, action) => {
    switch (action.type) {
      case FETCH_SERIES_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_TRENDING_SERIES_SUCCESS:
        return {
          loading: false,
          trendingSeries: action.payload,
          topRatedSeries: [...state.topRatedSeries],
          error: '',
        };
      case FETCH_TOP_RATED_SERIES_SUCCESS:
        return {
          loading: false,
          trendingSeries: [...state.trendingSeries],
          topRatedSeries: action.payload,
          error: '',
        };
      case FETCH_SERIES_FAILURE:
        return {
          loading: false,
          trendingSeries: [],
          topRatedSeries: [],
          error: action.payload,
        };
      default:
        return state;
    }
}

export default seriesReducer;