import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { fetchDetails, fetchVideos, fetchCast } from '../../redux';

import Nav from './../../components/Nav';
import MovieDetails from "./../../components/MovieDetails";
import SeriesDetails from "../../components/SeriesDetails";
import Footer from "../../components/Footer";

import { API_KEY } from "../../utilities/constants";

const StyledContainer = styled.div`
  margin-top: 100px;
`;

const Details = () => {
  const entityId = window.location.search.split('=').pop();
  const isMovie = window.location.search.includes("serieId") ? false : true;

  const castSelector = useSelector((state) => state.cast);
  const detailSelector = useSelector((state) => state.details);
  const videoSelector = useSelector((state) => state.videos);
  const dispatch = useDispatch();

  useEffect(() => {
    isMovie ? 
      dispatch(fetchCast(`movie/${entityId}/credits?api_key=${API_KEY}&language=en-US`))
    :
      dispatch(fetchCast(`tv/${entityId}/credits?api_key=${API_KEY}&language=en-US`));
    // eslint-disable-next-line
  }, [entityId]);

  useEffect(() => {
    isMovie ? 
      dispatch(fetchDetails(`movie/${entityId}?api_key=${API_KEY}&language=en-US`))
    :
      dispatch(fetchDetails(`tv/${entityId}?api_key=${API_KEY}&language=en-US`));
    // eslint-disable-next-line
  }, [entityId]);

  useEffect(() => {
    isMovie ? 
      dispatch(fetchVideos(`movie/${entityId}/videos?api_key=${API_KEY}&language=en-US`))
    :
      dispatch(fetchVideos(`tv/${entityId}/videos?api_key=${API_KEY}&language=en-US`));
    // eslint-disable-next-line
  }, [entityId]);

  return (
    <>
        <Nav />
        <StyledContainer>
          {isMovie ? 
            <MovieDetails 
              details={detailSelector?.detailsObject}
              videos={videoSelector?.videos}
              cast={castSelector?.cast} 
            /> :
            <SeriesDetails
              details={detailSelector?.detailsObject}
              videos={videoSelector?.videos}
              cast={castSelector?.cast} 
            />
          }
        </StyledContainer>
        <Footer />
    </>
  )
};

export default Details;