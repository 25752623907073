import React from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import TheatersIcon from '@mui/icons-material/Theaters';
import LiveTvIcon from '@mui/icons-material/LiveTv';

import { setFilter } from '../../redux';

import { MOVIE, TV } from '../../utilities/constants';

const StyledTab = styled.div`
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  margin-top: 80px;
`;

const TabsContainer = styled.div`
  display: flex;
  gap: 50px;
  padding: 0 8px;

  @media (min-width: 768px) {
    padding: 0 50px;
  }
`;

const ActiveLinkState = `
  :after {
    background-color: white;
    border-radius: 2px;
    bottom: -4px;
    content: '';
    display: inline-block;
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
  }
`

const StyledLink = styled.a`
  color: white;
  font-size: 24px;
  font-weight: 500;
  margin-right: 16px;
  position: relative;

  :hover {
    cursor: pointer;
    
    ${ActiveLinkState}
  }

  ${({ isActive }) => (isActive && css`
    ${ActiveLinkState}
  `)}
`;

const IconText = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
`;

const TabSwitcher = () => {
  const dispatch = useDispatch();
  const filterSelector = useSelector((state) => state.filter);

  const navigate = useNavigate();

  const moviesOnClick = () => {
    if (filterSelector.filter === TV) 
      dispatch(setFilter(MOVIE)); 
    navigate('/');
  };

  const seriesOnClick = () => {
    if (filterSelector.filter === MOVIE) 
      dispatch(setFilter(TV)); 
    navigate('/tv');
  };

  return (
    <StyledTab>
        <TabsContainer>
          <IconText>
            <TheatersIcon sx={{color: "#fff"}} />
            <StyledLink isActive={filterSelector.filter === MOVIE} onClick={moviesOnClick}>Movies</StyledLink>
          </IconText>
          <IconText>
            <LiveTvIcon sx={{color: "#fff"}} />
            <StyledLink isActive={filterSelector.filter === TV} onClick={seriesOnClick}>Series</StyledLink>
          </IconText>
        </TabsContainer>
    </StyledTab>
  );
};

export default TabSwitcher;