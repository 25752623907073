import instance from '../../../utilities/axios';

export const FETCH_CAST_REQUEST = 'FETCH_CAST_REQUEST';
export const FETCH_CAST_SUCCESS = 'FETCH_CAST_SUCCESS';
export const FETCH_CAST_FAILURE = 'FETCH_CAST_FAILURE';

export const fetchCastRequest = () => ({
  type: FETCH_CAST_REQUEST,
});

export const fetchCastSuccess = (cast) => ({
  type: FETCH_CAST_SUCCESS,
  payload: cast,
});

export const fetchCastFailure = (error) => ({
  type: FETCH_CAST_FAILURE,
  payload: error,
});

export const fetchCast = (fetchUrl) => (dispatch) => {
  dispatch(fetchCastRequest());
  instance
    .get(fetchUrl)
    .then((response) => {
      // response.data is the cast result
      const cast = response.data.cast;
      dispatch(fetchCastSuccess(cast));
    })
    .catch((error) => {
      dispatch(fetchCastFailure(error.message));
    });
};
