import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/home';
import Search from './pages/search';
import Error from './pages/error';
import Details from './pages/details';
import TV from './pages/tv';

import './App.css';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route path="/tv" element={<TV/>} />
          <Route path="/search" element={<Search/>}  />
          <Route path="/details" element={<Details/>}  />
          <Route element={<Error/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
