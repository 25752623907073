import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { fetchSearch, setQuery } from '../../redux';

import { API_KEY, baseUrl, MOVIE } from '../../utilities/constants';
import truncate from '../../utilities/truncate';

import NotFoundImg from './not-found.jpeg';
import FooterContent from '../FooterContent';

const StyledFooter = styled.footer`
  background-color: whitesmoke;
`;

const LoadingFooter = styled.footer`
  background-color: whitesmoke;
  bottom: 0;
  left: 35%;
  position: fixed;
`

const StyledResults = styled.div`
  margin: 80px 0;
`;

const StyledLoader = styled(CircularProgress)`
  left: 48%;
  position: fixed;
  top: 48%;
`

const SearchResult = () => {
  const querySelector = useSelector((state) => state.query);
  const searchSelector = useSelector((state) => state.search);
  const filterSelector = useSelector((state) => state.filter);
  const dispatch = useDispatch();

  let fetchUrl = filterSelector.filter === MOVIE ?
   `/search/movie?query=${querySelector.query}&api_key=${API_KEY}` :
   `/search/tv?query=${querySelector.query}&api_key=${API_KEY}`

  const navigate = useNavigate();
  const location = useLocation();

  const onClickHandler = (id) => {
    filterSelector.filter === MOVIE ?
      navigate(`/details?movieId=${id}`) :
      navigate(`/details?serieId=${id}`);
  }

  const getLastQuery = () =>
    location.search.substr(
      location.search.indexOf('=') + 1,
      location.search.length,
    );

  useEffect(() => {
    /* 
      if searchbar is cleared & back key wasn't pressed & the
      page was reloaded, then get whatever text was last present in the
      searchbar before the reload & fetch search results for that text
      instead of empty string
    */
    if (!querySelector.query && !querySelector.backPressed) {
      // eslint-disable-next-line
      fetchUrl = filterSelector.filter === MOVIE ?
       `/search/movie?query=${getLastQuery()}&api_key=${API_KEY}` :
       `/search/tv?query=${getLastQuery()}&api_key=${API_KEY}`;
      dispatch(setQuery(getLastQuery(), false));
    }
    // eslint-disable-next-line no-unused-expressions
    querySelector.query && dispatch(fetchSearch(fetchUrl));
    navigate(`/search?query=${querySelector.query}`);
  }, [fetchUrl]);

  return (
    <>
      {searchSelector.loading ? (
        <StyledLoader />
      ) : (
        <StyledResults>
          <Container maxWidth="md">
            <Grid container spacing={4}>
              {searchSelector.searchMovies.map((mov) => (
                <Grid item key={mov.id} xs={12} sm={6} md={4}>
                  <Card sx={{ height: "100%" }}>
                    <CardMedia
                      component="img"
                      image={
                        // eslint-disable-next-line no-nested-ternary
                        mov.backdrop_path
                          ? `${baseUrl}${mov.backdrop_path}`
                          : mov.poster_path
                          ? `${baseUrl}${mov.poster_path}`
                          : NotFoundImg
                      }
                      height="140"
                      title={mov.title ? mov.title : mov.original_name}
                    />
                    <CardContent>
                      <Typography 
                        style={{cursor: "pointer"}} 
                        gutterBottom variant="h5" 
                        component="h2" 
                        onClick={() => onClickHandler(mov.id)}
                      >
                        {mov.title ? mov.title : mov.original_name}
                      </Typography>
                      <Typography>
                        {mov.overview
                          ? truncate(mov.overview, 150)
                          : 'Plot is unknown...'}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </StyledResults>
      )}
      {searchSelector?.loading ? 
        <LoadingFooter>
          <FooterContent />
        </LoadingFooter> 
        : 
        <StyledFooter>
          <FooterContent />
      </StyledFooter>
      }
    </>
  );
}

export default SearchResult;
