import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchMovies } from '../../redux';

import Footer from '../../components/Footer';
import Movies from '../../components/Movies';
import Nav from '../../components/Nav';
import TabSwitcher from '../../components/TabSwitcher';

import requests from '../../utilities/requests';

const Home = () => {
  const popular = "popular";
  const trending = "trending";
  const upcoming = "upcoming";
  const dispatch = useDispatch();
  const movieSelector = useSelector((state) => state.movie);

  useEffect(() => {
    dispatch(fetchMovies(requests.fetchUpcoming, upcoming));
    // eslint-disable-next-line
  }, [requests.fetchUpcoming]);

  useEffect(() => {
    dispatch(fetchMovies(requests.fetchPopular, popular));
    // eslint-disable-next-line
  }, [requests.fetchPopular]);

  useEffect(() => {
    dispatch(fetchMovies(requests.fetchTrendingMovies, trending));
    // eslint-disable-next-line
  }, [requests.fetchTrendingMovies]);

  return (
    <>
      <Nav />
      <TabSwitcher />
      <Movies
        title="What's Popular"
        isLargeRow
        movies={movieSelector?.popularMovies}
      />
      <Movies
        title={"Latest & Upcoming Movies".replace("&", "\u0026")}
        isLargeRow
        movies={movieSelector?.upcomingMovies}
      />
      <Movies
        title="Trending Movies"
        isLargeRow
        movies={movieSelector?.trendingMovies}
      />
      <Footer />
    </>
  )
};

export default Home;
