const extractKey = (videosList) => {
  if (!videosList.length) return;

  for (let video of videosList) {
    if (video.type === 'Trailer' && video.site === 'YouTube') {
      return video.key;
    }
  }
};

export default extractKey;