import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

import { setQuery } from '../../redux';

import { MOVIE } from '../../utilities/constants';

import SearchLogo from './search.png';

// ------------------------------ Styled Components -----------------------
const StyledNav = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  /* Animation */
  transition-timing-function: ease-in;
  transition: all 0.5s;

  ${({ active }) =>
    active && `
      background-color: #111;
  `}
`;

const StyledNavLogo = styled.img`
  cursor: pointer;
  margin-left: 10px;
  width: 100px;

  @media (min-width: 768px) {
    width: 200px;
  }
`;

const StyledNavAvatar = styled.img`
  margin-right: 10px;
  width: 30px;
`;

const SearchBar = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  width: 200px;

  @media (min-width: 768px) {
    width: 250px;
  }
`;

const StyledIcon = styled.img`
  cursor: pointer;
  height: 20px;
  margin-left: 10px;
  width: 20px;
`;

const StyledInput = styled.input`
  border: none;
  height: 20px;
  width: 150px;

  :focus {
    outline: none;
  }

  @media (min-width: 768px) {
    width: 200px;
  }
`;

const Nav = () => {
  const querySelector = useSelector((state) => state.query);
  const filterSelector = useSelector((state) => state.filter);
  const [inputValue, setInputValue] = useState(querySelector.query);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchInput = (input) => {
    if (!input) {
      dispatch(setQuery(input, true));
    } else dispatch(setQuery(input, false));
  };

  // eslint-disable-next-line
  const debounceFn = useCallback(debounce(searchInput, 1000), []);

  const browseToHome = () => {
    dispatch(setQuery('', false));
    filterSelector.filter === MOVIE ? navigate('/') : navigate(`/tv`);
  }

  const changeHandler = (e) => {
    setInputValue(e.target.value);
    debounceFn(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    navigate(`/search?query=${querySelector.query}`);
  };

  useEffect(() => {
    if (!inputValue) setInputValue(querySelector.query);
    // eslint-disable-next-line
  }, [querySelector]);

  return (
    <div data-testid="navbar">
      <StyledNav active>
        <StyledNavLogo
          onClick={browseToHome}
          src="assets/tmdb_logo.svg"
          alt="TMDB Logo"
        />
        <SearchBar>
          <StyledIcon
            src={SearchLogo}
            alt="search-logo"
            onClick={submitHandler}
          />
          <form onSubmit={submitHandler}>
            <StyledInput
              placeholder="Search..."
              value={inputValue}
              onChange={changeHandler}
            />
          </form>
        </SearchBar>
        <StyledNavAvatar src="assets/avatar_logo.png" alt="Avatar Logo" />
      </StyledNav>
    </div>
  );
}

export default Nav;
