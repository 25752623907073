import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const Error = () => {
  const navigate = useNavigate();

  const clickHandler = () => {
    navigate('/');
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={clickHandler}>
        Back to Home page
      </Button>
      <img src="assets/lost.png" alt="404 error" style={{ width: '100%' }} />
    </>
  );
}

export default Error;
