import instance from '../../../utilities/axios';

export const FETCH_MOVIES_REQUEST = 'FETCH_MOVIES_REQUEST';

export const FETCH_POPULAR_MOVIES_SUCCESS = 'FETCH_POPULAR_MOVIES_SUCCESS';
export const FETCH_TRENDING_MOVIES_SUCCESS = 'FETCH_TRENDING_MOVIES_SUCCESS';
export const FETCH_UPCOMING_MOVIES_SUCCESS = 'FETCH_UPCOMING_MOVIES_SUCCESS';

export const FETCH_MOVIES_FAILURE = 'FETCH_MOVIES_FAILURE';

export const fetchMoviesRequest = () => ({
  type: FETCH_MOVIES_REQUEST,
});

export const fetchTrendingMoviesSuccess = (movies) => ({
  type: FETCH_TRENDING_MOVIES_SUCCESS,
  payload: movies,
});

export const fetchPopularMoviesSuccess = (movies) => ({
  type: FETCH_POPULAR_MOVIES_SUCCESS,
  payload: movies,
});

export const fetchUpcomingMoviesSuccess = (movies) => ({
  type: FETCH_UPCOMING_MOVIES_SUCCESS,
  payload: movies,
});

export const fetchMoviesFailure = (error) => ({
  type: FETCH_MOVIES_FAILURE,
  payload: error,
});

export const fetchMovies = (fetchUrl, category) => (dispatch) => {
  dispatch(fetchMoviesRequest());
  instance
    .get(fetchUrl)
    .then((response) => {
      // response.data is the movies
      const movies = response.data.results;
      dispatch(
        category === 'trending'
          ? fetchTrendingMoviesSuccess(movies)
          : category === 'upcoming' 
          ? fetchUpcomingMoviesSuccess(movies)
          : fetchPopularMoviesSuccess(movies)
      );
    })
    .catch((error) => {
      // error.message is the error message
      dispatch(fetchMoviesFailure(error.message));
    });
};
