import instance from '../../../utilities/axios';

export const FETCH_VIDEOS_REQUEST = 'FETCH_VIDEOS_REQUEST';
export const FETCH_VIDEOS_SUCCESS = 'FETCH_VIDEOS_SUCCESS';
export const FETCH_VIDEOS_FAILURE = 'FETCH_VIDEOS_FAILURE';

export const fetchVideosRequest = () => ({
  type: FETCH_VIDEOS_REQUEST,
});

export const fetchVideosSuccess = (videos) => ({
  type: FETCH_VIDEOS_SUCCESS,
  payload: videos,
});

export const fetchVideosFailure = (error) => ({
  type: FETCH_VIDEOS_FAILURE,
  payload: error,
});

export const fetchVideos = (fetchUrl) => (dispatch) => {
  dispatch(fetchVideosRequest());
  instance
    .get(fetchUrl)
    .then((response) => {
      // response.data is the Videos result
      const videos = response.data.results;
      dispatch(fetchVideosSuccess(videos));
    })
    .catch((error) => {
      // error.message is the error message
      dispatch(fetchVideosFailure(error.message));
    });
};
