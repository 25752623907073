import { MOVIE } from '../../../utilities/constants';
import { SET_FILTER } from './action';

const intitialState = {
  filter: MOVIE
};

const filterReducer = (state = intitialState, action) => {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
};

export default filterReducer;
