import instance from '../../../utilities/axios';

export const FETCH_SEARCH_REQUEST = 'FETCH_SEARCH_REQUEST';
export const FETCH_SEARCH_SUCCESS = 'FETCH_SEARCH_SUCCESS';
export const FETCH_SEARCH_FAILURE = 'FETCH_SEARCH_FAILURE';

export const fetchSearchRequest = () => ({
  type: FETCH_SEARCH_REQUEST,
});

export const fetchSearchSuccess = (movies) => ({
  type: FETCH_SEARCH_SUCCESS,
  payload: movies,
});

export const fetchSearchFailure = (error) => ({
  type: FETCH_SEARCH_FAILURE,
  payload: error,
});

export const fetchSearch = (fetchUrl) => (dispatch) => {
  dispatch(fetchSearchRequest());
  instance
    .get(fetchUrl)
    .then((response) => {
      // response.data is the search result
      const search = response.data.results;
      dispatch(fetchSearchSuccess(search));
    })
    .catch((error) => {
      // error.message is the error message
      dispatch(fetchSearchFailure(error.message));
    });
};
