import { combineReducers } from 'redux';

import movieReducer from './reducer/movie/reducer';
import queryReducer from './reducer/query/reducer';
import searchReducer from './reducer/search/reducer';
import detailsReducer from './reducer/details/reducer';
import videosReducer from './reducer/videos/reducer';
import seriesReducer from './reducer/series/reducer';
import castReducer from './reducer/cast/reducer';
import filterReducer from './reducer/filter/reducer';

const rootReducer = combineReducers({
  movie: movieReducer,
  query: queryReducer,
  search: searchReducer,
  details: detailsReducer,
  videos: videosReducer,
  series: seriesReducer,
  cast: castReducer,
  filter: filterReducer
});

export default rootReducer;
