import React from 'react';
import { useNavigate } from 'react-router-dom';

import SearchResult from '../../components/SearchResult';
import Nav from '../../components/Nav';

const Search = () => {
  const navigate = useNavigate();

  window.onpopstate = () => {
    navigate("/");
  }

  return (
    <>
      <Nav />
      <SearchResult />
    </>
  );
}

export default Search;
