import {
  FETCH_MOVIES_REQUEST,
  FETCH_TRENDING_MOVIES_SUCCESS,
  FETCH_POPULAR_MOVIES_SUCCESS,
  FETCH_UPCOMING_MOVIES_SUCCESS,
  FETCH_MOVIES_FAILURE,
} from './action';

const moviesInitialState = {
  loading: false,
  trendingMovies: [],
  popularMovies: [],
  upcomingMovies: [],
  error: '',
};

const movieReducer = (state = moviesInitialState, action) => {
  switch (action.type) {
    case FETCH_MOVIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TRENDING_MOVIES_SUCCESS:
      return {
        loading: false,
        upcomingMovies: [...state.upcomingMovies],
        trendingMovies: action.payload,
        popularMovies: [...state.popularMovies],
        error: '',
      };
    case FETCH_POPULAR_MOVIES_SUCCESS:
      return {
        loading: false,
        upcomingMovies: [...state.upcomingMovies],
        popularMovies: action.payload,
        trendingMovies: [...state.trendingMovies],
        error: '',
      };
    case FETCH_UPCOMING_MOVIES_SUCCESS:
      return {
        loading: false,
        upcomingMovies: action.payload,
        popularMovies: [...state.popularMovies],
        trendingMovies: [...state.trendingMovies],
        error: '',
      };
    case FETCH_MOVIES_FAILURE:
      return {
        loading: false,
        upcomingMovies: [],
        trendingMovies: [],
        popularMovies: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default movieReducer;
