import instance from '../../../utilities/axios';

export const FETCH_DETAILS_REQUEST = 'FETCH_DETAILS_REQUEST';
export const FETCH_DETAILS_SUCCESS = 'FETCH_DETAILS_SUCCESS';
export const FETCH_DETAILS_FAILURE = 'FETCH_DETAILS_FAILURE';

export const fetchDetailsRequest = () => ({
  type: FETCH_DETAILS_REQUEST,
});

export const fetchDetailsSuccess = (details) => ({
  type: FETCH_DETAILS_SUCCESS,
  payload: details,
});

export const fetchDetailsFailure = (error) => ({
  type: FETCH_DETAILS_FAILURE,
  payload: error,
});

export const fetchDetails = (fetchUrl) => (dispatch) => {
  dispatch(fetchDetailsRequest());
  instance
    .get(fetchUrl)
    .then((response) => {
      const details = response.data;
      dispatch(fetchDetailsSuccess(details));
    })
    .catch((error) => {
      dispatch(fetchDetailsFailure(error.message));
    });
};
